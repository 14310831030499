<template>
  <AppAsideRight v-if="!loading && !$vuetify.breakpoint.xsOnly">
    <template #prepend>
      <VContainer>
        <VRow>
          <VCol>
            <h1
              class="tt-text-title-2 ml-4 mt-3"
            >
              {{ $t('menu.goals') }}
            </h1>
          </VCol>
        </VRow>
      </VContainer>
    </template>
    <template #default>
      <VContainer data-test="goal-aside-right-list-goals">
        <VRow>
          <VCol
            cols="12"
          >
            <div
              v-for="(goal,i) in listGoals"
              :key="`card-goal-${i}`"
            >
              <GoalCard
                :type="type"
                :user-id="userId"
                shadow="hover"
                class="pa-4"
                :goal="goal"
              />
              <VDivider
                v-if="i+1!==listGoals.length"
                class="my-3"
              />
            </div>
          </VCol>
        </VRow>
      </VContainer>
    </template>
  </AppAsideRight>
</template>

<script>
import AppAsideRight from '@/components/app/AppAsideRight.vue';
import GoalCard from '@/components/cards/GoalCard.vue';
import { mapActions, mapGetters } from 'vuex';
import { GET_GOALS_ACTION, GET_TRAINEE_GOALS_ACTION } from '@/plugins/vuex/actionTypes';
import { GOAL_TYPES } from '@/helpers/constants';

export default {
  name: 'GoalAsideRight',
  components: { GoalCard, AppAsideRight },
  props: {
    type: {
      type: String,
      default: GOAL_TYPES.personal,
      validator: (value) => [GOAL_TYPES.personal, GOAL_TYPES.trainee].includes(value),
    },
    userId: {
      type: [String, Number],
      default: 0,
    },
    goalId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters('goal', ['goals', 'goalsByUserId']),
    listGoals() {
      if (this.type === GOAL_TYPES.trainee) {
        return this.goalsByUserId(this.userId);
      }
      return this.goals;
    },
  },
  async mounted() {
    if (this.type === GOAL_TYPES.personal) {
      this.loadPersonalGoals();
    } else if (this.type === GOAL_TYPES.trainee) {
      this.loadTraineeGoals();
    }
  },
  methods: {
    ...mapActions('goal', { getGoals: GET_GOALS_ACTION, getTraineeGoals: GET_TRAINEE_GOALS_ACTION }),
    async loadPersonalGoals() {
      try {
        this.loading = true;
        await this.getGoals();
      } catch (e) {
        // TODO обработчик на ошибку
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },
    async loadTraineeGoals() {
      try {
        this.loading = true;
        await this.getTraineeGoals({ userId: this.userId });
      } catch (e) {
        // TODO обработчик на ошибку
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>

</style>
